<template>
  <div id="tooltip-carousel" :class="styleClass" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div v-for="(image, index) in images" :key="index" class="carousel-item" :class="[index === 0 ? 'active' : '']">
        <div class="row">
          <div v-for="(i, idx) in image" :key="idx" :class="[images.length > 2 ? 'col' : 'col-sm-3']">
            <a
            class="col-sm-3 col-3 configurator__carousel-i"
            href="#tooltip-carousel">
              <span v-if="i && i.type === 'video'" class="position-relative">
                <img
                  v-if="i && i.type === 'video'"
                  :src="`https://img.youtube.com/vi/${i.path}/default.jpg`"
                  class="configurator__carousel-img"
                  :data-image-id="i.path"
                  @click="setTooltipImageActive(i)"
                />
                <img @click="setTooltipImageActive(i)" src="@/assets/img/play-btn.png" class="yt-play-btn"/>
              </span>
              <img
                v-else
              :src="$filters.image(i.path)"
              class="configurator__carousel-img"
              :data-image-id="i.path"
              @click="setTooltipImageActive(i)"
            />
            </a>
          </div>
        </div>
      </div>
    </div>
    <a
      class="bg-transparent arrow-left"
      href="#tooltip-carousel"
      role="button"
      data-bs-slide="prev"
      data-bs-target="#tooltip-carousel"
    >
      <i class="fas fa-chevron-left"></i>
    </a>
    <a
      class="bg-transparent arrow-right"
      href="#tooltip-carousel"
      role="button"
      data-bs-slide="next"
      data-bs-target="#tooltip-carousel"
    >
      <i class="fas fa-chevron-right"></i>
    </a>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
// import { useStore } from 'vuex'
// import { onMounted } from '@vue/runtime-core'
import _ from 'lodash'
export default {
  name: 'Carousel',
  props: {
    styleClass: {
      type: String
    },
    gallery: {
      required: true,
      type: Array
    }
  },
  setup (props) {
    // const store = useStore()
    const images = computed(() => {
      const imgs = props.gallery.map((v, k) => {
        return {
          path: v.path,
          sort: v.sort,
          type: v.type,
          active: k === 0
        }
      })
      const result = _.orderBy(imgs, ['sort'], ['asc'])
      const limit = 4
      const arr = []
      for (let i = 0; i < result.length; i += limit) {
        arr.push(result.slice(i, i + limit))
      }
      return arr
    })
    // onMounted(() => {
    //   document.querySelector('#tooltip-carousel').addEventListener('click', (e) => {
    //     setImageIndex(e)
    //   })
    // })
    // function setImageIndex (e) {
    //   const index = e.target.dataset.imageId
    //   store.commit('configurator/SET_EXAMPLE_IMAGE_INDEX', index)
    // }
    function openModal (i) {
      console.log(i)
    }
    function setTooltipImageActive (image) {
      const gallery = props.gallery.find(i => i.path === image.path)
      if (gallery) {
        props.gallery.filter(i => {
          i.active = false
        })
        gallery.active = true
      }
    }
    return {
      images,
      openModal,
      setTooltipImageActive
    }
  }
}
</script>
<style scoped>
.carousel-item {
  transition: transform 0.2s ease-in-out;
}
.arrow-left {
  bottom: 20px;
  left: -16px;
  position: absolute;
}
.arrow-right {
  bottom: 20px;
  right: -16px;
  position: absolute;
}
.yt-play-btn {
  position: absolute;
  top: 0px;
  width: 45px;
  left: 40px;
}
</style>
